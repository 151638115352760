import { useAuth0 } from '@auth0/auth0-react';

const LoginButton = ({className}) => {
    const { loginWithRedirect, isAuthenticated } = useAuth0();

    return (
        !isAuthenticated && (
            <button className={className} onClick={()=>loginWithRedirect()}>
                Sign In
            </button>
        )
        
    );
}

export default LoginButton;