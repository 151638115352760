import React, { useState } from 'react';
import './TabSwitcher.scss';
const TabSwitcher = ({ tabs }) => {
  // State to track the active tab
  const [activeTab, setActiveTab] = useState(tabs[0]);
  // Handler to switch tabs
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  return (
    <div>
      {/* Tab navigation buttons */}
      <div className="tabs">
        {tabs.map((tab, index) => (
          <button
            key={index}
            onClick={() => handleTabClick(tab)}
            className={activeTab.label === tab.label ? 'active' : ''}
          >
            {tab.label}
          </button>
        ))}
      </div>
      {/* Render the content of the active tab */}
      <div className="tab-content">{activeTab.content}</div>
    </div>
  );
};
export default TabSwitcher;