import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import './Patients.scss';
import HeaderSidebar from '../../components/HeaderSidebar/HeaderSidebar';

import usePatientsAll from '../../shared/hooks/usePatientsAll';

const Patients = () => {

    const { patients, patientsIsLoading, patientsHasError } = usePatientsAll();
    console.log(patients);

    return (
        <div className="patients">
            <HeaderSidebar
                title="All Patients"
                subcategory=""
                subtitle="All Patients"
            />

            <div className="main-content">
                <div className="content-area">
                    <div className="panel">

                        <table>
                            <thead>
                                <tr>
                                    <th>First Name</th>
                                    <th>Last Name</th>
                                    <th>Status</th>
                                    <th>DOB</th>
                                    <th>Gender</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                { patients ? (
                                    
                                    patients.map(patient => (
                                        <tr>
                                            <td>{patient.PTNameFirst}</td>
                                            <td>{patient.PTNameLast}</td>
                                            <td>{patient.Status_Primary}</td>
                                            <td>{patient.PTDOB}</td>
                                            <td>{patient.PTSex}</td>
                                            <td><a href="">View Patient</a></td>
                                        </tr>
                                    ))
                                    
                                ) : (<p>There are no patients</p>) }
                        </tbody>
                        </table>
                        

                    </div>
                </div>
            </div>

        </div>
    );

}


export default Patients;