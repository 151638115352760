import React from 'react';
import './Modal.scss';

const Modal = ({ isModalOpen, onModalClose, children }) => {

    if(!isModalOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <button className="close-modal" onClick={onModalClose}>&times;</button>
                {children}
                {/* <button className="close-modal-btn" onClick={onModalClose}>Close Modal</button> */}
            </div>
        </div>
    );


}

export default Modal;