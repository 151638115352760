import { useState, useEffect } from 'react';
import { getOpenClaims } from '../../../../shared/services/claimService';

const useClaimsOpen = () => {
    const [ claims, setClaims ] = useState([]);
    const [ claimsIsLoading, setClaimsIsLoading ] = useState(true);
    const [ claimsHasError, setClaimsHasError ] = useState(null);

    useEffect(() =>{ 
        const fetchClaims = async () => {
            try {
                const claimsData = await getOpenClaims();
                if (!claimsData) {
                throw new Error('No claims data received');
                }
                setClaims(claimsData);
            } catch (error) {
                console.error('Error in fetchClaims:', error);
                setClaimsHasError(error);
            } finally {
                setClaimsIsLoading(false);
            }
        }
        fetchClaims();
    }, []);

    return { claims, claimsIsLoading, claimsHasError }

}

export default useClaimsOpen;