export const formatDate = (isoString) => {
    const date = new Date(isoString);
  
    const day = String(date.getDate()).padStart(2, '0');  // Pad with leading zero
    const month = String(date.getMonth() + 1).padStart(2, '0');  // getMonth is 0-indexed
    const year = date.getFullYear();
  
    return `${month}/${day}/${year}`;
  };

export const formatDateInputBox = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);

    // Get the year, month, and day components
    const year = date.getFullYear();

    // Months are zero-based in JavaScript, so we add 1
    const month = String(date.getMonth() + 1).padStart(2, '0');

    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`; // Returns 'YYYY-MM-DD'
};

export function currentDateTime() {
  const now = new Date();

  const year = now.getFullYear();

  // Months are zero-based in JavaScript, so add 1
  const month = String(now.getMonth() + 1).padStart(2, '0');

  const day = String(now.getDate()).padStart(2, '0');

  const hours = String(now.getHours()).padStart(2, '0');

  const minutes = String(now.getMinutes()).padStart(2, '0');

  const seconds = String(now.getSeconds()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}


export function convertToPSTAndFormatDateTime(dateStringFromDB, options = {}) {
  if (!dateStringFromDB) {
    return { date: 'N/A', time: 'N/A', timeZoneName: 'N/A' };
  }

  const {
    addDays = 0,
    timeZone = 'America/Los_Angeles',
  } = options;

  try {
    let isoDateString = dateStringFromDB.replace(' ', 'T');
    let dateObject = new Date(isoDateString);

    if (isNaN(dateObject)) {
      throw new Error('Invalid Date');
    }

    if (addDays !== 0) {
      dateObject.setUTCDate(dateObject.getUTCDate() + addDays);
    }

    // Date formatter for 'MM/DD/YYYY'
    const dateFormatter = new Intl.DateTimeFormat('en-US', {
      timeZone: timeZone,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });

    // Time formatter for 'hh:mm:ss AM/PM'
    const timeFormatter = new Intl.DateTimeFormat('en-US', {
      timeZone: timeZone,
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true, // Enable 12-hour time format with AM/PM
    });

    const formattedDate = dateFormatter.format(dateObject);
    const formattedTime = timeFormatter.format(dateObject);

    return { date: formattedDate, time: formattedTime };

  } catch (error) {
    console.error('Date/time formatting error:', error);
    return { date: 'Invalid Date', time: 'Invalid Time' };
  }
}