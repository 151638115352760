import React, { useState, useMemo, useEffect } from "react";
import usePaymentEventsMultiple from "../../../../../shared/hooks/usePaymentEventsMultiple";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

import PaymentEventRow from "./PaymentEventRow";

const PaymentEventsTab = ({ peIds }) => {
  const { paymentEvents, paymentEventsIsLoading, paymentEventsHasError } = usePaymentEventsMultiple(peIds);
  console.log(paymentEvents);


  return (
    <>
      {paymentEventsIsLoading === false ? (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Tag</TableCell>
                    <TableCell>Allowed Amount</TableCell>
                    <TableCell>Payable Amount</TableCell>
                    <TableCell>Claim Payment</TableCell>
                    <TableCell>Pt. Resp.</TableCell>
                    <TableCell>Payer</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paymentEvents.map((event) => (
                    <PaymentEventRow key={event.id} event={event} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          
      ) : (
        <p>No Payment Events</p>
      )}
    </>
  );
};

export default PaymentEventsTab;
