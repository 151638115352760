import { useState, useEffect } from 'react';
import { unmatchedClaims } from '../../shared/services/claimService';

const useClaimsUnmatched = () => {

    const [ claims, setClaims ] = useState([]);
    const [ claimsIsLoading, setClaimsIsLoading ] = useState(true);
    const [ claimsHasError, setClaimsHasError ] = useState(null);

    useEffect(()=>{
        const fetchUnmatchedClaims = async () => {
            try {
                const claimData = await unmatchedClaims();
                if(!claimData){
                    throw new Error('No unmatched claims found');
                }
                setClaims(claimData)
            } catch (error) {
                console.log('Error in fetchUnmatchedClaims', error);
                setClaimsHasError(error);
            } finally {
                setClaimsIsLoading(false)
            }
        };

        fetchUnmatchedClaims();

    },[]);

    return { claims, claimsIsLoading, claimsHasError };

}

export default useClaimsUnmatched;