import React, { useState, useEffect } from "react";
import axios from "axios";

const AppealsTab = ({ claim, claimId }) => {
  const [appealsData, setAppealsData] = useState({
    appeals_l1_date_submitted: claim.appeals_l1_date_submitted || "",
    appeals_l1_assigned_agent: claim.appeals_l1_assigned_agent || "",
    appeals_l1_status: claim.appeals_l1_status || "",
    appeals_l1_status_date: claim.appeals_l1_status_date || "",
    appeals_l2_date_submitted: claim.appeals_l2_date_submitted || "",
    appeals_l2_assigned_agent: claim.appeals_l2_assigned_agent || "",
    appeals_l2_status: claim.appeals_l2_status || "",
    appeals_l2_status_date: claim.appeals_l2_status_date || "",
    appeals_l3_date_submitted: claim.appeals_l3_date_submitted || "",
    appeals_l3_assigned_agent: claim.appeals_l3_assigned_agent || "",
    appeals_l3_status: claim.appeals_l3_status || "",
    appeals_l3_status_date: claim.appeals_l3_status_date || "",
  });

   const [isSubmitting, setIsSubmitting] = useState(false);
   const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    setAppealsData({
      appeals_l1_date_submitted: claim.appeals_l1_date_submitted,
      appeals_l1_assigned_agent: claim.appeals_l1_assigned_agent || "",
      appeals_l1_status: claim.appeals_l1_status || "",
      appeals_l1_status_date: claim.appeals_l1_status_date,
      appeals_l2_date_submitted: claim.appeals_l2_date_submitted,
      appeals_l2_assigned_agent: claim.appeals_l2_assigned_agent || "",
      appeals_l2_status: claim.appeals_l2_status || "",
      appeals_l2_status_date: claim.appeals_l2_status_date,
      appeals_l3_date_submitted: claim.appeals_l3_date_submitted,
      appeals_l3_assigned_agent: claim.appeals_l3_assigned_agent || "",
      appeals_l3_status: claim.appeals_l3_status || "",
      appeals_l3_status_date: claim.appeals_l3_status_date
    });
  }, [claim]);

  // Handle form submission for all appeal levels
  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent the default form submission behavior
    setIsSubmitting(true);
    setErrorMessage("");

    console.log(appealsData);

    // try {
    //   // Make the axios PATCH request with the entire appealsData object
    //   const response = await axios.patch(
    //     `${process.env.REACT_APP_API_URL}/api/v1/claims/appeals/${claimId}`,
    //     appealsData
    //   );
    //   // Handle the response as needed
    //   console.log("Server response:", response.data);
    //   // Optionally, display a success message
    // } catch (error) {
    //   console.error("Error submitting appeals data:", error);
    //   setErrorMessage("An error occurred while submitting the appeals data.");
    //   // Optionally, handle errors or display an error message
    // } finally {
    //   setIsSubmitting(false);
    // }
  };
  // Handle input changes
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setAppealsData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="container">
          {/* Level 1 Appeal */}
          <div className="contain-33">
            <h1>Level 1 Appeal</h1>
            <label htmlFor="appeals_l1_date_submitted">Date Submitted</label>
            <input
              type="date"
              name="appeals_l1_date_submitted"
              id="appeals_l1_date_submitted"
              value={appealsData.appeals_l1_date_submitted || ""}
              onChange={handleInputChange}
            />
            <br />
            <br />
            <label htmlFor="appeals_l1_assigned_agent">Assigned Agent</label>
            <select
              name="appeals_l1_assigned_agent"
              id="appeals_l1_assigned_agent"
              className="claim-status-dropdown"
              value={appealsData.appeals_l1_assigned_agent || ""}
              onChange={handleInputChange}
            >
              <option value="" disabled>
                --Select Agent--
              </option>
              <option value="chris@smilesmed.com">Chris Barbieri</option>
              <option value="jessiec@smilesmed.com">Jessie Cabillo</option>
              <option value="lanmar@smilesmed.com">Lanmar Sabar</option>
              <option value="ninan@smilesmed.com">Nina Navarro</option>
              <option value="michelley@smilesmed.com">Michelle Young</option>
            </select>
            <br />
            <br />
            <p>Result:</p>
            <br />
            <label htmlFor="appeals_l1_status">Level 1 Status</label>
            <select
              name="appeals_l1_status"
              id="appeals_l1_status"
              className="claim-status-dropdown"
              value={appealsData.appeals_l1_status || ""}
              onChange={handleInputChange}
            >
              <option value="" disabled>
                --Select Status--
              </option>
              <option value="Win">Win</option>
              <option value="Lost">Lost</option>
              <option value="Partial Win">Partial Win</option>
            </select>
            <br />
            <br />
            <label htmlFor="appeals_l1_status_date">Level 1 Result Date</label>
            <input
              type="date"
              name="appeals_l1_status_date"
              id="appeals_l1_status_date"
              value={appealsData.appeals_l1_status_date || ""}
              onChange={handleInputChange}
            />
            <br />
            <br />
          </div>
          {/* Level 2 Appeal */}
          <div className="contain-33">
            <h1>Level 2 Appeal</h1>
            <label htmlFor="appeals_l2_date_submitted">Date Submitted</label>
            <input
              type="date"
              name="appeals_l2_date_submitted"
              id="appeals_l2_date_submitted"
              value={appealsData.appeals_l2_date_submitted || ""}
              onChange={handleInputChange}
            />
            <br />
            <br />
            <label htmlFor="appeals_l2_assigned_agent">Assigned Agent</label>
            <select
              name="appeals_l2_assigned_agent"
              id="appeals_l2_assigned_agent"
              className="claim-status-dropdown"
              value={appealsData.appeals_l2_assigned_agent || ""}
              onChange={handleInputChange}
            >
              <option value="" disabled>
                --Select Agent--
              </option>
              <option value="chris@smilesmed.com">Chris Barbieri</option>
              <option value="jessiec@smilesmed.com">Jessie Cabillo</option>
              <option value="lanmar@smilesmed.com">Lanmar Sabar</option>
              <option value="ninan@smilesmed.com">Nina Navarro</option>
              <option value="michelley@smilesmed.com">Michelle Young</option>
            </select>
            <br />
            <br />
            <p>Result:</p>
            <br />
            <label htmlFor="appeals_l2_status">Level 2 Status</label>
            <select
              name="appeals_l2_status"
              id="appeals_l2_status"
              className="claim-status-dropdown"
              value={appealsData.appeals_l2_status || ""}
              onChange={handleInputChange}
            >
              <option value="" disabled>
                --Select Status--
              </option>
              <option value="Win">Win</option>
              <option value="Lost">Lost</option>
              <option value="Partial Win">Partial Win</option>
            </select>
            <br />
            <br />
            <label htmlFor="appeals_l2_status_date">Level 2 Result Date</label>
            <input
              type="date"
              name="appeals_l2_status_date"
              id="appeals_l2_status_date"
              value={appealsData.appeals_l2_status_date || ""}
              onChange={handleInputChange}
            />
            <br />
            <br />
          </div>
          {/* Level 3 Appeal */}
          <div className="contain-33">
            <h1>Level 3 Appeal</h1>
            <label htmlFor="appeals_l3_date_submitted">Date Submitted</label>
            <input
              type="date"
              name="appeals_l3_date_submitted"
              id="appeals_l3_date_submitted"
              value={appealsData.appeals_l3_date_submitted || ""}
              onChange={handleInputChange}
            />
            <br />
            <br />
            <label htmlFor="appeals_l3_assigned_agent">Assigned Agent</label>
            <select
              name="appeals_l3_assigned_agent"
              id="appeals_l3_assigned_agent"
              className="claim-status-dropdown"
              value={appealsData.appeals_l3_assigned_agent || ""}
              onChange={handleInputChange}
            >
              <option value="" disabled>
                --Select Agent--
              </option>
              <option value="chris@smilesmed.com">Chris Barbieri</option>
              <option value="jessiec@smilesmed.com">Jessie Cabillo</option>
              <option value="lanmar@smilesmed.com">Lanmar Sabar</option>
              <option value="ninan@smilesmed.com">Nina Navarro</option>
              <option value="michelley@smilesmed.com">Michelle Young</option>
            </select>
            <br />
            <br />
            <p>Result:</p>
            <br />
            <label htmlFor="appeals_l3_status">Level 3 Status</label>
            <select
              name="appeals_l3_status"
              id="appeals_l3_status"
              className="claim-status-dropdown"
              value={appealsData.appeals_l3_status || ""}
              onChange={handleInputChange}
            >
              <option value="" disabled>
                --Select Status--
              </option>
              <option value="Win">Win</option>
              <option value="Lost">Lost</option>
              <option value="Partial Win">Partial Win</option>
            </select>
            <br />
            <br />
            <label htmlFor="appeals_l3_status_date">Level 3 Result Date</label>
            <input
              type="date"
              name="appeals_l3_status_date"
              id="appeals_l3_status_date"
              value={appealsData.appeals_l3_status_date || ""}
              onChange={handleInputChange}
            />
            <br />
            <br />
          </div>
        </div>
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        <input
          type="submit"
          className="form-btn"
          value={isSubmitting ? "Submitting..." : "Submit Appeals"}
          disabled={isSubmitting}
        />
      </form>
    </div>
  );
};

export default AppealsTab;
