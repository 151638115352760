import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import "../../../style.scss";
import "./claims.scss";
import './ClaimDetails.scss';
import HeaderSidebar from "../../../components/HeaderSidebar/HeaderSidebar";
import { useParams } from "react-router-dom";
import useClaimDetails from "../../../shared/hooks/useClaimDetails";
import usePaymentEventsMultiple from "../../../shared/hooks/usePaymentEventsMultiple";
import PaymentEventsTable from "./components/ClaimDetails/PaymentEventTable";
import ClaimStatusSelect from "./components/ClaimStatusSelect/ClaimStatusSelect";
import ClaimNotesList from "./components/ClaimNotesList/ClaimNotesList";
import ClaimNotes from "./components/ClaimNotes/ClaimNotes";
import { updateClaim } from "../../../shared/services/claimService";
import { useNotification } from "../../../shared/components/Notify/Notify";
import Modal from "../../../shared/components/Modal/Modal";
import { createSingleClaimNote } from '../../../shared/services/claimNoteService';

const ClaimsDetail = () => {
  const { user, isLoading: authLoading, isAuthenticated } = useAuth0();
  const { claimId } = useParams(); // Grab the claim ID from the URL
  const { claim, claimIsLoading, claimHasError } = useClaimDetails(claimId);
  const [currentStatus, setCurrentStatus] = useState("");

  const notify = useNotification();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  // Extract user information
  const userPayload = {
    userName: user.name,
    userId: user.sub.split("|")[1],
  };

  // Set the initial status when claim data is available
  useEffect(() => {
    if (claim && claim.cpfsid) {
      setCurrentStatus(claim.cpfsid);
    }
  }, [claim]);

  // Check if claim and payment_event_ids are defined
  const paymentEventIds =
    claim && claim.payment_event_ids ? claim.payment_event_ids : "";
  const { paymentEvents, paymentEventsIsLoading, paymentEventsHasError } =
    usePaymentEventsMultiple(paymentEventIds);

  const handleStatusChange = (newStatus) => {
    setCurrentStatus(newStatus);
    console.log("New Status Selected:", newStatus);
  };

  const pushStatusChange = async () => {
    // Push the claim status change to the database on save click event
    console.log(currentStatus);
    const updatedData = {
      cpfsid: currentStatus,
      user_id: userPayload.userId,
    };

    const response = await updateClaim(claimId, updatedData);
    console.log(response);
    if (response.success == true) {
      notify("Claim Status Updated Successfully");
    }
  };

  const [newClaimNote, setNewClaimNote] = useState("");
  const handleClaimNoteChange = async (event) => {
    setNewClaimNote(event.target.value);
  }

  // Push the new claim note to the database
  const insertClaimNote = async () =>{
    const claimNotePayload = {
      claim_id: claim.id,
      user: userPayload.userName,
      note: newClaimNote,
      user_id: userPayload.userId
    }

    const response = await createSingleClaimNote(claim.id, claimNotePayload)
    console.log(response);
    if(response.status == 201){
      notify("Note Added Successfully");
      setIsModalOpen(false);
    }
  }

  if (claimIsLoading || paymentEventsIsLoading) {
    return <div>Loading...</div>;
  }

  if (claimHasError || paymentEventsHasError) {
    return <div>Error loading claim details.</div>;
  }


  return (
    <div className="claim-details">
      <HeaderSidebar
        title="Claim Detail"
        subcategory=""
        subtitle="Claim Detail Page"
      />
      <div className="main-content">
        <div className="content-area">
          {/* <Notify message="Record Saved Succesfully!" delay="1500" /> */}
          <div className="panel">
            <p>Update Status:</p> <br />
            <div className="container">
              <div className="contain-70">
                <ClaimStatusSelect
                  selectedStatus={currentStatus}
                  onStatusChange={handleStatusChange}
                />
              </div>
              <div className="contain-30">
                <button
                  onClick={pushStatusChange}
                  className="claims-submit-button"
                >
                  Save Status
                </button>
              </div>
            </div>
          </div>
          <br />

          <div className="container">
            <div className="contain-50">
              <div className="panel patient-data-contain">
                <p className="title">Patient Data:</p> 
                <hr className="hr-spacer"/>

                <table>
                  <tbody>
                    <tr>
                      <td>Patient</td>
                      <td>{claim.patient_name}</td>
                    </tr>
                    <tr>
                      <td>Patient Id</td>
                      <td>{claim.patient_id}</td>
                    </tr>
                    <tr>
                      <td>Current Balance:</td>
                      <td>{claim.claim_balance == null ? "0.00" : claim.claim_balance}</td>
                    </tr>
                    <tr>
                      <td>Payer:</td>
                      <td>{claim.payer_name}</td>
                    </tr>
                    <tr>
                      <td>Current Status:</td>
                      <td>{currentStatus}</td>
                    </tr>
                    <tr>
                      <td>Date of Service:</td>
                      <td>{claim.from_date}</td>
                    </tr>
                    <tr>
                      <td>Units:</td>
                      <td>{claim.units}</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
             
              </div>
            </div>
            <div className="contain-50">
              <div className="panel claim-notes-contain">
                <div className="container">
                  <div className="contain-50">
                    <p style={{marginTop:'10px'}}>Claim Notes:</p>
                  </div>
                  <div className="contain-50">
                    <button onClick={openModal} style={{ float: 'right' }}>Add Claim Note</button>
                    <Modal isModalOpen={isModalOpen} onModalClose={closeModal}>
                      <p style={{ fontSize: "18px" }}>Create Claim Note:</p>
                      <hr className="hr-spacer" style={{ display: 'none !important' }} />
                      {/* needs user information */}
                      {/* Needs the timestamp in db */}
                      {/* needs the note itself */}
                      {/* needs the claim id which comes from current state */}
                      <p style={{ fontSize: "13px" }}>
                        New Note for: {claim.patient_name} | {claim.id}
                      </p>
                      <textarea
                        style={{ marginBottom: "20px", marginTop: "10px" }}
                        name=""
                        id=""
                        placeholder="New Note ..."
                        onChange={handleClaimNoteChange}
                      ></textarea>
                      <button className="add-claim-note-save" onClick={insertClaimNote}>Save Note</button>
                    </Modal>
                  </div>
                </div>
                <hr className='hr-spacer'/>
                <div className="claim-notes">
                  <ClaimNotesList style={{marginTop:'20px'}} claimId={claim.id} />
                </div>
              </div>
            </div>
          </div>

          <br />
          <div className="panel">
            <PaymentEventsTable paymentEvents={paymentEvents} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClaimsDetail;
