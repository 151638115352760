import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  TextField,
  TablePagination,
} from "@mui/material";
import HeaderSidebar from "../../../components/HeaderSidebar/HeaderSidebar";
import "../../../style.scss";
import "./claims.scss";
import { useNotification } from "../../../shared/components/Notify/Notify";

import Modal from "../../../shared/components/Modal/Modal";
import useClaimsUnmatched from "../../../shared/hooks/useClaimsUnmatched";

import { createPaymentEvent } from "../../../shared/services/paymentEventService";

const ClaimsUnmatched = () => {
  const { user, isLoading: authLoading } = useAuth0();
  const { claims, claimsIsLoading, claimsHasError } = useClaimsUnmatched();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedClaim, setSelectedClaim] = useState(null);

  const notify = useNotification();

  const userPayload = {
    userName: user.name,
    userId: user.sub.split("|")[1],
  };

  const openModal = (claim) => {
    setSelectedClaim(claim);
    setStatementStart(claim.from_date || "");
    setPatientCtrlNumber(claim.claim_patient_ctrl_number || "");
    setIsModalOpen(true);
  };

  const resetFormFields = () => {
    setStatementStart("");
    setPayerName("");
    setPatientCtrlNumber("");
    setAllowedAmount("");
    setClaimPayment("");
    setPatientResp("");
    setRemitCptCode("");
    setPayerIcn("");
    setClaimStatusCode("");
    setCheckNumber("");
  }

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedClaim(null);
    resetFormFields();
  };

  
  // Form State
  const [statementStart, setStatementStart] = useState("");
  const [payerName, setPayerName] = useState("");
  const [patientCtrlNumber, setPatientCtrlNumber] = useState("");
  const [allowedAmount, setAllowedAmount] = useState("");
  const [claimPayment, setClaimPayment] = useState("");
  const [patientResp, setPatientResp] = useState("");
  const [remitCptCode, setRemitCptCode] = useState("");
  const [payerIcn, setPayerIcn] = useState("");
  const [claimStatusCode, setClaimStatusCode] = useState("");
  const [checkNumber, setCheckNumber] = useState("");

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    setIsSubmitting(true);
    setError(null);

    const remitData = {
      record_processed: 0,
      statement_start: statementStart,
      patient_ctrl_number: patientCtrlNumber,
      patient_id: patientCtrlNumber,
      payer_name: payerName,
      allowed_amount: allowedAmount,
      claim_payment: claimPayment,
      patient_resp: patientResp,
      cpt_code: remitCptCode,
      check_number: checkNumber,
      payer_icn: payerIcn,
      claim_status_code: claimStatusCode,
      user_id: userPayload.userId,
    };
    
    // All this needs to do is create a payment event and then the other side SHOULD pick this up
    let response = await createPaymentEvent(remitData);    
    setIsSubmitting(false);
    closeModal();
    

    notify("Remit Successfully Added For Claim");

  };

  return (
    <div className="claims-unmatched">
      <HeaderSidebar
        title="Unmatched Claims"
        subcategory=""
        subtitle="All Claims not matched to a Remit"
      />
      <div className="main-content">
        <div className="content-area">
          <div className="panel">
            <table>
              <thead>
                <tr>
                  <th>Claim Id</th>
                  <th>Patient Id</th>
                  <th>Patient Name</th>
                  <th>Date of Service</th>
                  <th>Claim Payer</th>
                  <th>CPT Code</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {claims ? (
                  claims.map((claim) => (
                    <tr key={claim.claim_id}>
                      <td>{claim.claim_id}</td>
                      <td>{claim.patient_id}</td>
                      <td>{claim.claim_patient_name}</td>
                      <td>{claim.from_date}</td>
                      <td>{claim.claim_payer_name}</td>
                      <td>{claim.claim_cpt_code}</td>
                      <td>
                        <button
                          onClick={() => {
                            openModal(claim);
                          }}
                        >
                          Open Modal
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <p>No claims</p>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <Modal isModalOpen={isModalOpen} onModalClose={closeModal}>
        {selectedClaim ? (
          <>
            <p style={{ marginBottom: "10px" }}>
              <strong>Patient Name:</strong> {selectedClaim.claim_patient_name}
            </p>
            <small
              style={{ fontSize: "12px", lineHeight: "14px", display: "block" }}
            >
              Note: The date of service has been auto set to match the claim.
            </small>
            <br />
            <hr className="hr-spacer" />
            <small>Use this if you need to assign a remit to a claim</small>
            <br />
            <br />
            <form onSubmit={handleSubmit}>
              <input
                type="date"
                value={selectedClaim.from_date}
                placeholder="Remit Statement Start"
                disabled
              />

              <input
                type="text"
                value={selectedClaim.claim_patient_ctrl_number}
                hidden
              />
              <input type="text" value={selectedClaim.patient_id} hidden />

              <br />
              <br />
              <input
                type="text"
                value={payerName}
                onChange={(e) => setPayerName(e.target.value)}
                placeholder="Payer Name"
              />

              <br />
              <br />
              <input
                type="text"
                value={allowedAmount}
                onChange={(e) => setAllowedAmount(e.target.value)}
                placeholder="Allowed Amount"
              />

              <br />
              <br />
              <input
                type="text"
                value={claimPayment}
                onChange={(e) => setClaimPayment(e.target.value)}
                placeholder="Claim Payment"
              />

              <br />
              <br />
              <input
                type="text"
                value={patientResp}
                onChange={(e) => setPatientResp(e.target.value)}
                placeholder="Patient Responsible"
              />

              <br />
              <br />
              <input
                type="text"
                value={remitCptCode}
                onChange={(e) => setRemitCptCode(e.target.value)}
                placeholder="Remit CPT Code"
              />

              <br />
              <br />
              <input
                type="text"
                value={payerIcn}
                onChange={(e) => setPayerIcn(e.target.value)}
                placeholder="Remit Payer ICN"
              />

              <br />
              <br />
              <input
                type="text"
                value={checkNumber}
                onChange={(e) => setCheckNumber(e.target.value)}
                placeholder="Remit Check Number"
              />

              <br />
              <br />
              <select
                value={claimStatusCode}
                onChange={(e) => setClaimStatusCode(e.target.value)}
              >
                <option value="" disabled>
                  Select Claim Status
                </option>
                <option value="1">Primary - Need More Information</option>
                <option value="19">Primary - Claim Received</option>
                <option value="2">Secondary</option>
                <option value="3">Clawback</option>
                {/* Add more options as needed */}
              </select>
              <button
                className="add-claim-modal-submit-btn"
                type="submit"
                disabled={isSubmitting}
                style={{ width: "100%", marginTop: "15px" }}
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </button>
            </form>
          </>
        ) : (
          <p>Data not available</p>
        )}
      </Modal>
    </div>
  );
};

export default ClaimsUnmatched;
