import React, { useState, useEffect } from "react";

const DocumentsTab = ({ claimId }) => {

    return (
        <p>Document Tab</p>
    );

}

export default DocumentsTab;