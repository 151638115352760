// !! Copy and paste of ClaimUnprocessed and changed the status on the backend.  Not the way that we want to handle moving forward but
// !! the billing team was in a pinch and this was the fastest route to solve the problem.  Moving forward need to use the get claim by status
// !! and build out a better way to handle state inside of multiple components.  Basic replacement here is the useClaimsUnprocessed hook with useClaimsOpen.jsx

import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import "../../../style.scss";
import "./claims.scss";
import HeaderSidebar from "../../../components/HeaderSidebar/HeaderSidebar";
import Loader from '../../../images/loque-preloader.gif';
import useClaimsOpen from "./hooks/useClaimsOpen";
import RemitRow from "./components/RemitRow/RemitRow";

import { createClaimNote } from "../../../shared/services/claimNoteService";

import {
  updatePaymentEvent,
  createPaymentEvent,
} from "../../../shared/services/paymentEventService";

import { updateClaim, createClaim } from "../../../shared/services/claimService";
import AddClaimModal from "./components/AddClaimModal/AddClaimModal";

const ClaimsOpen = () => {
  const { user, isLoading: authLoading, isAuthenticated } = useAuth0();
  const { claims, claimsIsLoading, claimsHasError } = useClaimsOpen();

  // Extract user information
  const userPayload = {
    userName: user.name,
    userId: user.sub.split("|")[1],
  };

  const [expandedRow, setExpandedRow] = useState(null); // To track which row is expanded
  const [claimsList, setClaimsList] = useState([]); // Local state for claims
  const [createClaimData, setCreateClaimData] = useState(null);

  const [searchQuery, setSearchQuery] = useState(''); // State for search input

  const handleCreateNewClaim = async (data) => {
    setCreateClaimData(data); // Set the claim data in state
    const claimData = {user_id: userPayload.userId, ...data};
    
    const response = await createClaim(claimData);
    console.log(response);
  };

  // Initialize claimsList when claims are fetched
  useEffect(() => {
    if (!claimsIsLoading && !claimsHasError && claims && claimsList.length === 0) {
      setClaimsList(claims);
    }
  }, [claims, claimsIsLoading, claimsHasError, claimsList.length]);

  // Toggle expand/collapse
  const toggleRow = (claim_id) => {
    if (expandedRow === claim_id) {
      setExpandedRow(null); // Collapse if the same row is clicked
    } else {
      setExpandedRow(claim_id); // Expand the selected row
    }
  };

  // if (claimsIsLoading) return <img src={Loader} className="loader-gif" alt="Smiles Loading Gif" />;
  if (claimsHasError)
    return <p>Error fetching claims: {claimsHasError.message}</p>;

  const handleRemitRowSubmit = async (data) => {
    try {
      // Send data to the backend or process as needed
      console.log("Data received from RemitRow:", data);
      const claimStatus = data.selectedClaimStatus;

      // Process claim notes if any
      if (data.claimNotes.length > 0) {
        await createClaimNote(data.claimId, data.claimNotes);
      }

      // Prepare remits for update
      let remitUpdateObj = [];
      if (data.remits.length > 0) {
        for (let i = 0; i < data.remits.length; i++) {
          if (!data.remits[i].isManual) {
            remitUpdateObj.push({
              id: data.remits[i].id,
              allowed_amount: data.remits[i].allowedAmount,
              claim_payment: data.remits[i].claimPayment,
              patient_resp: data.remits[i].patientResp,
              payment_event_status_type: data.remits[i].statusType,
              user_id: userPayload.userId,
            });
          }
        }
      }

      // Update existing remits
      if (remitUpdateObj.length > 0) {
        for (let i = 0; i < remitUpdateObj.length; i++) {
          await updatePaymentEvent(remitUpdateObj[i].id, remitUpdateObj[i]);
        }
      }

      // Prepare remits for creation
      let remitCreateObj = [];
      if (data.remits.length > 0) {
        for (let i = 0; i < data.remits.length; i++) {
          if (data.remits[i].isManual) {
            remitCreateObj.push({
              allowed_amount: data.remits[i].allowedAmount,
              claim_payment: data.remits[i].claimPayment,
              patient_resp: data.remits[i].patientResp,
              payment_event_status_type: data.remits[i].statusType,
              statement_start: data.remits[i].statementStart,
              cpt_code: data.remits[i].cpt_code,
              payer_name: data.remits[i].payerName,
              patient_id: data.remits[i].patientId,
              patient_ctrl_number: data.remits[i].patientId,
              claim_status_code: 1000, // setting this to 1000 so it doesn't confuse the claim_status_code of 0
              claim_adj_codes: data.remits[i].claim_adj_codes,
              check_number: data.remits[i].checkNumber,
              payment_type_id: data.remits[i].paymentTypeId,
              is_manual: 1,
            });
          }
        }
      }

      // Create new remits and collect their IDs
      let responseIds = [];
      if (remitCreateObj.length > 0) {
        for (let i = 0; i < remitCreateObj.length; i++) {
          let response = await createPaymentEvent(remitCreateObj[i]);
          if (response) {
            responseIds.push(response.id);
          }
        }
      }

      // Gather all payment_event_ids from both existing and newly created remits
      let remitIds = [];
      if (data.remits.length > 0) {
        for (let i = 0; i < data.remits.length; i++) {
          if (!data.remits[i].isManual) {
            remitIds.push(data.remits[i].id);
          }
        }
      }

      remitIds = [...remitIds, ...responseIds];
      const finalRemitIds = remitIds.join(", ");

      // Update the claim with the new data
      const claimUpdateObj = {
        payment_event_ids: finalRemitIds,
        cpfsid: claimStatus,
        user_id: userPayload.userId,
        claim_balance: data.claimBalance
      };

      console.log("Updating claim with ID:", data.claimId);
      console.log("Claim update object:", claimUpdateObj);
      const claimResponse = await updateClaim(data.claimId, claimUpdateObj);

      // Update payment events to mark them as processed
      if (claimResponse.data && claimResponse.data.affectedRows > 0) {
        console.log("Remit IDs to update:", remitIds);
        for (let i = 0; i < remitIds.length; i++) {
          const remitId = Number(remitIds[i]);
          console.log("Updating payment event with ID:", remitId);
          const updateObj = {
            user_id: userPayload.userId,
            record_processed: 1,
          };
          const peUpdateResponse = await updatePaymentEvent(remitId, updateObj);
          console.log("Update response:", peUpdateResponse);
        }
      }

      // Remove the processed claim from the claimsList
      setClaimsList((prevClaimsList) =>
        prevClaimsList.filter((claim) => claim.claim_id !== data.claimId)
      );

      // Collapse the expanded row if it was open
      setExpandedRow(null);

      alert("Claim Updated Successfully");
    } catch (error) {
      console.error("Error processing claim:", error);
      alert("An error occurred while processing the claim.");
    }
  };

  // Filtered claims based on search query
  const filteredClaimsList = claimsList.filter((claim) => {
    if (!searchQuery) return true; // If search query is empty, return all claims
    const query = searchQuery.toLowerCase();
    return (
      (claim.patient_name && claim.patient_name.toLowerCase().includes(query)) ||
      (claim.patient_id && claim.patient_id.toString().toLowerCase().includes(query)) ||
      (claim.claim_id && claim.claim_id.toString().toLowerCase().includes(query)) ||
      (claim.payer_name && claim.payer_name.toLowerCase().includes(query)) ||
      (claim.from_date && claim.from_date.toLowerCase().includes(query)) ||
      (claim.total_charge && claim.total_charge.toString().toLowerCase().includes(query))
    );
  });

  return (
    <div className="unprocessed-claims">
      <HeaderSidebar
        title="Open Claims"
        subcategory=""
        subtitle="Open Claims Worksheet"
      />
      <div className="main-content">
        <div className="content-area">
            <div className="contain-100">
              <input
                type="text"
                placeholder="Search claims..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="search-input"
              />
              <br /><br />
              <AddClaimModal className="add-claim-btn" />
              <br />
            </div>

          <div className="panel">
            <table className="" style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th></th>
                  <th>Patient Name</th>
                  <th>Patient Number</th>
                  <th>Date of Service</th>
                  <th>Payer Name</th>
                  <th>Total Charge</th>
                </tr>
              </thead>
              <tbody>
              {
  claimsIsLoading ? (
    <tr>Loading Claims ...</tr>
  ) : Array.isArray(filteredClaimsList) && filteredClaimsList.length > 0 ? (
    filteredClaimsList.map((claim) => (
      <React.Fragment key={claim.claim_id}>
        <tr className="claim-row">
          <td>
            <span
              onClick={() => toggleRow(claim.claim_id)}
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
            >
              {expandedRow === claim.claim_id ? (
                <i className="bx bx-chevron-up"></i>
              ) : (
                <i className="bx bx-chevron-down"></i>
              )}
            </span>
          </td>
          <td>{claim.patient_name}</td>
          <td>
            {claim.patient_id} | {claim.claim_id}
          </td>
          <td>{claim.from_date}</td>
          <td>{claim.payer_name}</td>
          <td>${claim.total_charge}</td>
        </tr>
        {/* Conditionally render the expanded details row */}
        {expandedRow === claim.claim_id && (
          <tr>
            <td colSpan="6">
              <RemitRow
                paymentEvents={claim.payment_events}
                claimId={claim.claim_id}
                patientId={claim.patient_id}
                userPayload={userPayload}
                claimPayer={claim.payer_name}
                onSubmit={handleRemitRowSubmit}
              />
            </td>
          </tr>
        )}
      </React.Fragment>
    ))
  ) : (
    <tr>
      <td colSpan="6">No claims available.</td>
    </tr>
  )
}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClaimsOpen;
