import React, { useState } from 'react';
// !! Need to get status types the new way
import { currentDateTime } from "../../../../../shared/utils/dates";
// import ClaimStatusTypes from '../BillingClaimsRemits/ClaimStatusType';

const ClaimDeniedForm = ({ claim, user }) => {
    
    // adjust the previos state below by passing in the claim object from above
    const [formData, setFormData] = useState({
        denial_payer_priority: claim.denial_payer_priority || '',
        denial_status: claim.denial_status || '',
        denial_allowable: claim.denial_allowable || '',
        denial_payable: claim.denial_payable || '',
        denial_codes: claim.denial_codes || '',
        denial_reason: claim.denial_reason || '',
        denial_agent: claim.denial_agent || '',
        denial_next_step_date: claim.denial_next_step_date || '',
        denial_next_step_actions: claim.denial_next_step_actions || '',
        denial_last_action_taken: claim.denial_last_action_taken || '',
    });
    // Handle form input changes
    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };
    // Handle claim status change (from ClaimStatusTypes)
    const handleStatusChange = (status) => {
        setFormData({
            ...formData,
            claim_status: status // Update claim_status in formData
        });
    };
    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent the form from refreshing the page
        // Construct the payload to send to the backend
        const payload = {
            ...formData,
            claimId: claim?.id, // Assuming you're passing the claim object with an ID
            denied_agent: user.name,
            denial_last_update_date: currentDateTime()
        };
        console.log(payload);
        // try {
        //     const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/claims/denied/${payload.claimId}`, {
        //         method: 'PATCH',
        //         headers: {
        //             'Content-Type': 'application/json'
        //         },
        //         body: JSON.stringify(payload)
        //     });
        //     if (response.ok) {
        //         const result = await response.json();
        //         alert('Claim updated successfully'); // You can handle success as you see fit
        //         window.location.reload();
        //     } else {
        //         alert('Failed to update claim');
        //     }
        // } catch (error) {
        //     console.error('Error updating claim:', error);
        //     alert('Error updating claim');
        // }
    };
    return (
        <div>
            <form onSubmit={handleSubmit}>
                <div className="container">
                    <div className="contain-50">
                    <label htmlFor="denial_payer_priority">Payer Priority</label>
                    <select
                        name="denial_payer_priority"
                        id="denial_payer_priority"
                        value={formData.denial_payer_priority}
                        onChange={handleChange}
                    >
                        <option value="" disabled>--Select Payer Priority--</option>
                        <option value="Primary">Primary</option>
                        <option value="Secondary">Secondary</option>
                        <option value="Tertiary">Tertiary</option>
                    </select>
                        
                        <br /><br />
                        <label htmlFor="denial_status">Denied Result/Status</label><br />
                        <select 
                            id="denial_status" 
                            name="denial_status" 
                            value={formData.denial_status} 
                            className="claim-status-dropdown"
                            onChange={handleChange}>
                            <option value="" disabled>--Select Denied Result--</option>
                            <optgroup label="Working">
                                <option value="Working">Working</option>
                                <option value="Resubmitted">Resubmitted</option>
                                <option value="Appeal">Appealed</option>
                                <option value="On Hold">On Hold</option>
                            </optgroup>
                            <optgroup label="Completed">
                                <option value="Paid">Paid</option>
                                <option value="Partially Paid">Partially Paid</option>
                                <option value="Write Off">Write Off</option>
                                <option value="Collections">Collections</option>
                                <option value="Other">Other</option>
                            </optgroup>
                        </select>
                        <br /><br />
                        <label htmlFor="">Allowable</label><br />
                        <input type="text" id="denial_allowable" name="denial_allowable" value={formData.denial_allowable} onChange={handleChange} />
                        <br /><br />
                        
                        <label htmlFor="">Payable</label><br />
                        <input type="text" id="denial_payable" name="denial_payable" value={formData.denial_payable} onChange={handleChange} />
                                                
                        <br /><br />
                        <label htmlFor="">Denial Codes</label><br />
                        <input type="text" id="denial_codes" name="denial_codes" value={formData.denial_codes} onChange={handleChange} />
                        <br /><br />
                        
                        <label htmlFor="">Denial Reason</label><br />
                        <input type="text" id="denial_reason" name="denial_reason" value={formData.denial_reason} onChange={handleChange} />
                        <br /><br />
                    </div>
                    <div className="contain-50">
                        <label htmlFor="denial_agent">Assigned Agent</label>
                        <select 
                            name="denial_agent"
                            id="denial_agent"
                            value={formData.denial_agent}
                            className="claim-status-dropdown"
                            onChange={handleChange}
                            >
                                <option value="" disabled>--Select Agent--</option>
                                <option value="chris@orderpm.com">Chris Barbieri</option>
                                <option value="michelle@smilesmed.com">Michelle</option>
                                <option value="lanmar@smilesmed.com">Lanmar</option>
                                <option value="andrew@smilesmed.com">Andrew Price</option>
                        </select>
                        
                        <br /><br />
                        <label htmlFor="">Next Step</label><br />
                        <input type="date" id="denial_next_step_date" name="denial_next_step_date" value={formData.denial_next_step_date} onChange={handleChange} />
                        <br /><br />
                        <label htmlFor="">Actions for Next Step</label><br />
                        <textarea
                            id="denial_next_step_actions"
                            name="denial_next_step_actions"
                            value={formData.denial_next_step_actions}
                            onChange={handleChange}
                            rows="5"
                            cols="55"
                        />
                        <br /><br />
                        <label htmlFor="">Last Action Taken</label><br />
                        <textarea
                            id="denial_last_action_taken"
                            name="denial_last_action_taken"
                            value={formData.denial_last_action_taken}
                            onChange={handleChange}
                            rows="5"
                            cols="55"
                        />
                        <br /><br />
                        
                        {/* Pass handleStatusChange to update claim_status in formData */}
                        {/* <ClaimStatusTypes selectValue={formData.claim_status} onSelectChange={handleStatusChange} /> */}
                    </div>
                </div>
                <br /><br /><br />
                <button type="submit" className="denied-form-btn">Update Claim</button>
            </form>
        </div>
    );
};
export default ClaimDeniedForm;