import React from 'react';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { useNavigate } from "react-router-dom";

const ClaimsDeniedTable = ({ claimsDenied }) => {

    const navigate = useNavigate();

    return (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Patient ID</TableCell>
                <TableCell>Patient Name</TableCell>
                <TableCell>Date of Service</TableCell>
                <TableCell>Payer Name</TableCell>
                <TableCell>Current Balance</TableCell>
                <TableCell>Tag</TableCell>
                <TableCell>ACtion</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {claimsDenied.map((claim) => (
                <TableRow key={claim.id} data-claim-id={claim.id}>
                    <TableCell>{claim.patient_id}</TableCell>
                    <TableCell>{claim.patient_name}</TableCell>
                    <TableCell>{claim.from_date}</TableCell>
                    <TableCell>{claim.payer_name}</TableCell>
                    <TableCell>{claim.current_balance || '$0.00'}</TableCell>
                    <TableCell><span className="tag tag-denied">Denied</span></TableCell>
                    <TableCell>
                        <Button
                          onClick={() =>
                            navigate(`/billing/claims/denials/${claim.id}`)
                          }
                        >
                          View Claim
                        </Button>
                      </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      );
};

export default ClaimsDeniedTable;