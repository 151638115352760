import React, { createContext, useState, useContext, useCallback } from "react";
import './Notify.scss';
const NotificationContext = createContext();

export const useNotification = () => useContext(NotificationContext);

export const NotificationProvider = ({ children }) => {
    const [notification, setNotification] = useState({ message: "", isVisible: false });

    const showNotification = useCallback((message, delay = 2000) => {
        setNotification({ message, isVisible: true });

        setTimeout(() => {
            setNotification({ message: "", isVisible: false });
        }, delay);
    }, []);

    return (
        <NotificationContext.Provider value={showNotification}>
            {children}
            {notification.isVisible && <Notify message={notification.message} />}
        </NotificationContext.Provider>
    );
};

const Notify = ({ message }) => {

    const notify = useNotification();
    const hideNotify = () => {
        notify("", false);
    }

    return (
        <div className="notify notify-show">
            <p className="notify-message">{message}</p>
            <a href="#" className="hide-notify" onClick={hideNotify}>&times;</a>
        </div>
    );
};
