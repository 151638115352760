import { useState, useEffect } from 'react';
import { getClaimById } from '../../shared/services/claimService';

const useClaimDetails = (claimId) => {

    const [ claim, setClaim ] = useState([]);
    const [ claimIsLoading, setClaimIsLoading ] = useState(true);
    const [ claimHasError, setClaimHasError ] = useState(null);

    useEffect(() => {
        const fetchClaim = async () => {
            try {
                const claimData = await getClaimById(claimId);
                if(!claimData){
                    throw new Error('No Claim Data Found.');
                }
                setClaim(claimData);
            } catch (error) {
                console.log('Error in fetchClaim:', error)
                setClaimHasError(error);
            } finally {
                setClaimIsLoading(false);
            }
        };
        fetchClaim();
    }, [claimId]);

    return { claim, claimIsLoading, claimHasError };

}

export default useClaimDetails;
