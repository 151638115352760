import React, { useState, useEffect } from "react";
import useClaimNotesByClaimId from "../../../../../shared/hooks/useClaimNotesByClaimId";

const NotesTab = ({ claimId }) => {

    const { claimNotes, claimNotesIsLoading, claimNotesHasError } = useClaimNotesByClaimId(claimId);
    console.log(claimNotes)
    return (
        <>
            { claimNotesIsLoading !== true ? (
                claimNotes.map((claim) => {
                    return (
                        <>
                            <p>{claim.user}</p>
                            <p>{claim.note}</p>
                            <p>{claim.created_at}</p>
                        </>
                    );
                })
            ) 
            : 
            (
                <p>Claim Notes Loading</p>
            ) 
            }
        </>
    );

}

export default NotesTab;