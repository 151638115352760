import apiClient from '../../api/apiClient';

export const getAllPatients = async () => {

    try {
        const response = await apiClient.get('/patients');
        return response.data.data;
    } catch (error) {
        console.log(error);
        throw error;
    }

};

export const getPatientById = async (patientId) => {
    try {
        const response = await apiClient.get(`/patients/${patientId}`);
        return response.data.data;
    } catch (error) {
        console.error(`Error fetching patient with ID: ${patientId}`);
        throw error;
    }
}