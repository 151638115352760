import { useState, useEffect } from 'react';
import { getDeniedClaims } from '../../../../shared/services/claimService';

const useClaimsDenied = () => {
    const [ claimsDenied, setClaimsDenied ] = useState([]);
    const [ claimsDeniedIsLoading, setClaimsDeniedIsLoading ] = useState(true);
    const [ claimsDeniedHasError, setClaimsDeniedHasError ] = useState(null);

    useEffect(() => {
        const fetchClaimsDenied = async () => {
          try {
            const claimsDeniedData = await getDeniedClaims();
            if (!claimsDeniedData) {
              throw new Error('No claims data received');
            }
            setClaimsDenied(claimsDeniedData);
          } catch (error) {
            console.error('Error in fetchClaimsDenied:', error);
            setClaimsDeniedHasError(error);
          } finally {
            setClaimsDeniedIsLoading(false);
          }
        };
        fetchClaimsDenied();
      }, []);
      
    
    return { claimsDenied, claimsDeniedIsLoading, claimsDeniedHasError };

}

export default useClaimsDenied;