import React from "react";
import { TableRow, TableCell } from "@mui/material";

const PaymentEventRow = ({ event }) => {
  // Extract the status from the event
  const {
    id,
    patient_id,
    allowed_amount,
    payable_amount,
    patient_resp,
    patient_ctrl_number,
    charge,
    claim_payment,
    payer_name,
    claim_status_code,
    payment_date,
    check_number,
  } = event;

  // Determine statusType based on claim_status_code
  let statusType;
  let statusMessage;
  let tagClass;
  const claimStatusCode = claim_status_code || null;
  if (claimStatusCode === "1" || claimStatusCode === "19") {
    statusType = 1; // Primary
    statusMessage = "Primary";
    tagClass = "tag tag-primary"
  } else if (claimStatusCode === "2") {
    statusType = 2; // Secondary
    statusMessage = "Secondary";
    tagClass = "tag tag-secondary"
  } else if (claimStatusCode === "22") {
    statusType = 3; // Clawback
    statusMessage = "Clawback";
    tagClass = "tag tag-clawback"
  } else {
    statusType = 4; // Manual Adjustment or other
    statusMessage = "Manual Adjustment";
    tagClass = "tag tag-manual"
  }

  return (
    <TableRow data-peid={id}>
      <TableCell><span className={tagClass}>{statusMessage}</span></TableCell>
      <TableCell>{allowed_amount}</TableCell>
      <TableCell>{payable_amount}</TableCell>
      <TableCell>{claim_payment}</TableCell>
      <TableCell>{patient_resp}</TableCell>
      <TableCell>{payer_name}</TableCell>
    </TableRow>
  );
};

export default PaymentEventRow;
