import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import "../../../style.scss";
import HeaderSidebar from "../../../components/HeaderSidebar/HeaderSidebar";
import "./ClaimsDenied.scss";

import useClaimsDenied from "./hooks/useClaimsDenied";
import ClaimsDeniedTable from "./components/ClaimsDeniedTable/ClaimsDeniedTable";

const ClaimsDenied = () => {
  const { user, isLoading: authLoading, isAuthenticated } = useAuth0();
  const { claimsDenied, claimsDeniedIsLoading, claimsDeniedHasError } = useClaimsDenied();

  const [searchQuery, setSearchQuery] = useState("");

  // Filter claims based on the search query
  const filteredClaims = claimsDenied?.data?.filter((claim) =>
    claim.patient_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="claims-denied">
      <HeaderSidebar title="Denied Claims" subcategory="" subtitle="Claims Denied" />

      <div className="main-content">
        <div className="content-area">
          <div className="search-bar">
            <input
              type="text"
              placeholder="Search by Patient Name"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>

          {claimsDeniedIsLoading ? (
            <p>Loading claims...</p>
          ) : (
            <ClaimsDeniedTable claimsDenied={filteredClaims} />
          )}
        </div>
      </div>
    </div>
  );
};

export default ClaimsDenied;
