import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import "../../../../style.scss";
import HeaderSidebar from "../../../../components/HeaderSidebar/HeaderSidebar";
import { useParams } from "react-router-dom";
import useClaimDetails from "../../../../shared/hooks/useClaimDetails";

import ClaimDeniedForm from "../components/ClaimsDeniedForm/ClaimsDeniedForm";

import TabSwitcher from "../components/TabSwitcher/TabSwitcher";
import PaymentEventsTab from "../components/TabSwitcher/PaymentEventsTab";
import AppealsTab from "../components/TabSwitcher/AppealsTab";
import NotesTab from "../components/TabSwitcher/NotesTab";
import DocumentsTab from "../components/TabSwitcher/DocumentsTab";




const ClaimDeniedDetails = () => {
  const { claimId } = useParams(); // Grab the claim ID from the URL
  const { claim, claimIsLoading, claimHasError } = useClaimDetails(claimId);
  const { user, isLoading: authLoading } = useAuth0();
  console.log(claim);

  if (claimIsLoading) {
    return <div>Loading...</div>;
  }

  if (claimHasError) {
    return <div>Error loading claim details.</div>;
  }

  return (
    <div className="claims-denied-details">
      <HeaderSidebar
        title="Claim Denied Details"
        subcategory=""
        subtitle="Claim Denied Details Page"
      />
      <div className="main-content">
        <div className="content-area">
          <div className="container panel">
            <h3>{claim.patient_name}</h3>
            <p>Payer: {claim.payer_name}</p>
            <p>HCPCS Item(s): {claim.cpt_code}</p>
            <p>ICN: {claim.payer_icn || "Missing"}</p>
            <p>DoS: {claim.from_date}</p>
            <p>Denied: {claim.denied_date}</p>
          </div>
          <br />
          <div className="container">
            <div className="panel contain-40">
              <ClaimDeniedForm claim={claim} user={user} />
              <br />
              <p>Last Updated on and By claim.denial_agent</p>
              <p>
                <strong>
                  Need to auto set a date when someone selects the denied
                  status. If statement in backend
                </strong>
              </p>
            </div>
            <div className="panel contain-60">
              <TabSwitcher 
                tabs={[
                    {label: 'Payment Events', content: <PaymentEventsTab peIds={claim.payment_event_ids} />},
                    {label: 'Appeals', content: <AppealsTab claim={claim} claimId={claim.id} />},
                    {label: 'Claim Notes', content: <NotesTab claimId={claim.id} />},
                    {label: 'Documents', content: <DocumentsTab claimId={claim.id} />},
                    
                ]}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClaimDeniedDetails;
