import { useState, useEffect } from 'react';
import { getAllPatients } from '../../../src/shared/services/patientService';

const usePatientsAll = () => {

    const [ patients, setPatients ] = useState([]);
    const [ patientsIsLoading, setPatientsIsLoading ] = useState(true);
    const [ patientsHasError, setPatientsHasError ] = useState(null);

    useEffect(() => {

        const fetchAllPatients = async () => {
            try {
                const patientData = await getAllPatients();
                if(!patientData){
                    throw new Error('No patient data received');
                }
                setPatients(patientData);
            } catch (error) {
                console.error('Error in fetchAllPatients');
                setPatientsHasError(error);
            } finally {
                setPatientsIsLoading(false);
            }
        }

        fetchAllPatients();

    }, []);

    return { patients, patientsIsLoading, patientsHasError };

}

export default usePatientsAll;