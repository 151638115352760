import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import './App.scss';
import './fonts.css';
import './style.scss';

import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';

// import Loader from './images/smiles-loader.gif';
import Loader from './images/loque-preloader.gif';
import Logo from './images/new-logo.png';

import Sidebar from './components/Sidebar/Sidebar';
import Header from './components/Header/Header';
import Dashboard from './features/Dashboard/Dashboard';
import ClaimsUnprocessed from './features/Billing/Claims/ClaimsUnprocessed';
import Claims from './features/Billing/Claims/Claims';
import ClaimDetails from './features/Billing/Claims/ClaimDetails';
import ClaimsDenied from './features/Billing/Denials/ClaimsDenied';
import ClaimDeniedDetails from './features/Billing/Denials/ClaimDeniedDetails/ClaimDeniedDetails'
import ClaimsOpen from './features/Billing/Claims/ClaimsOpen';
import ClaimsUnmatched from './features/Billing/Claims/ClaimsUnmatched';

import Patients from './features/Patients/Patients';

import LoginButton from './components/LoginButton/LoginButton';

const App = () => {
    const { user, isLoading, isAuthenticated } = useAuth0();
    return (
        <>
        {isLoading && (
            <div className="loading">
                <img src={Loader} className="loader-gif" alt="Smiles Loading Gif" />
            </div>
        )}

        {/* Is not logged in */}
        {!isAuthenticated && (
            <div className="login-screen">
                <div className="login-contain">
                    {/* <div>Not Authenticated</div> */}
                    <img src={Logo} className="login-logo" alt="LoQue Med | A Smiles Web Company" />
                    <p className="login-desc">Welcome to LoQue Med. <br/>Please sign in to get started.</p>
                    <LoginButton className="login-screen-btn"/>
                    <p className="login-footer">Powered By Smiles Med Supply</p>
                </div>
            </div>
        )}


        {/* IS logged in */}
        {isAuthenticated && (
            <Router>
                <div className="App">                                        
                    <Routes>
                        <Route index element={<Dashboard/>}></Route>
                        <Route path="/billing/claims/" element={<Claims/>}></Route>
                        <Route path="/billing/claims/unprocessed" element={<ClaimsUnprocessed/>}></Route>
                        <Route path="/billing/claims/open" element={<ClaimsOpen/>}></Route>
                        <Route path="/billing/claims/denials" element={<ClaimsDenied/>}></Route>
                        <Route path="/billing/claims/unmatched" element={<ClaimsUnmatched/>}></Route>
                        <Route path="/billing/claims/denials/:claimId" element={<ClaimDeniedDetails/>}></Route>
                        <Route path="/billing/claims/:claimId" element={<ClaimDetails/>}></Route>
                        
                        
                        <Route path="/patients" element={<Patients/>}></Route>
                        {/* 
                        <Route path="/patients/demographics/:id" element={<PatientDetails/>}></Route>
                        <Route path="/profile" element={<Profile/>}></Route>
                        <Route path="/grid" element={<Grid/>}></Route>
                        <Route path="/billing" element={<Billing/>}></Route>
                        <Route path="/billing/claims" element={<Claims/>}></Route>
                        <Route path="/billing/remits/:patientId" element={<Remits/>}></Route>

                        
                        
                        <Route path="/billing/claims/collections" element={<Collections/>}></Route> */}
                    </Routes>
                </div>
            </Router>
        )}
        </>
    );
}


export default App;